(function ($) {
    $(document).ready(function () {

        function updateHeader() {
            if ($(window).scrollTop() > ($('.home.section').height() - $('.background').height() - 80)) {
                $('header.header').addClass('solid shrink');
            } else {
                $('header.header').removeClass('solid shrink');
            }
        }

        if (window.location.href) {
            var target = window.location.hash.replace(/#/g, '.');
            $(window).scrollTo(target, 500, {
                onAfter: function () {
                    updateHeader();
                }
            });
        }

        $('.toggle').on('click', function () {
            $('body').toggleClass('show');
        });

        $('nav.nav').on('click', function () {
            $('body').removeClass('show');
        });

        $(window).scroll(function () {
            updateHeader();
        });

        $('nav.nav a').on('click', function () {
            var target = $(this).attr('href').replace(/#/g, '.');
            $(window).scrollTo(target, 500, {
                onAfter: function () {
                    updateHeader();
                }
            });
        });

        $('[data-index]').on('click', function () {
            console.log('clicked');
            var $this = $(this);
            var $img = $this.attr('data-img-src');
            var $title = $this.attr('data-title');

            $('.current-img').attr('src', $img);
            $('#modal .title').text($title);

            $('#modal').addClass('show');

        });

        $('#modal .x').on('click', function () {
            $('#modal').removeClass('show');
        });

        $('#modal').on('click', function () {
            if ($(this).is('#modal')) {
                $('#modal').removeClass('show');
            }
        });

        $('.modal-container').click(function (e) {
            e.stopPropagation();
        });

        // validation
        function email() {
            var $email = $('#email');
            var val = $email.val();
            var pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gi;
            var end = pattern.test(val);
            if (end) {
                $email.removeClass('err');
            } else {
                $email.addClass('err');
            }
            return end;
        }

        function phone() {
            var $phone = $('#phone');
            var val = $phone.val();
            var pattern = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/im;
            var end = pattern.test(val);
            if (end || !val) {
                $phone.removeClass('err');
            } else {
                $phone.addClass('err');
            }
            return end;
        }

        function required(el) {
            var $el = $(el);

            if ($el.val().length > 2) {
                $el.removeClass('err');
                return true;
            } else {
                $el.addClass('err');
                return false;
            }
        }

        $('#name').on('blur input change', function () {
            required('#name');
        });

        $('#email').on('blur input change', function () {
            email();
        });

        $('#phone').on('blur input change', function () {
            phone();
        });

        $('#message').on('blur input change', function () {
            required('#message');
        });

        $('div[data-img-src]').each(function () {
            var $div = $(this);
            $div.css({
                'background': 'url(' + $div.attr('data-img-src') + ') no-repeat' +
                ' center center',
                'background-size': 'cover'
            });
        });

        $('#contact').on('submit', function () {
            var response = grecaptcha.getResponse();

            if(!response) {
                $('.error-message').addClass('show');
            }

            if (required('#name') && email() && required('#message') && response) {

                $.post(window.location.toString(), {
                    email: $('#email').val(),
                    name: $('#name').val(),
                    phone: $('#phone').val(),
                    message: $('#message').val(),
                    'g-recaptcha-response': response
                }, function () {
                    $('.success-message').addClass('show');
                    $('.error-message').removeClass('show');
                    grecaptcha.reset();
                    setTimeout(function () {
                        window.location.hash = 'message-sent';
                    }, 100);
                    $('#contact')[0].reset();
                    setTimeout(function () {
                        $('.success-message').removeClass('show');
                        $('.error-message').removeClass('show');
                    }, 10000);

                });
                return false;
            } else {
                return false;
            }
        });

        // var updateTweet = function () {
        //     $.get('/api/last-tweet', function (tweet) {
        //
        //         // $('.twitter .tweet-text').html(addHash(addUser(addLink(tweet.text))));
        //         // $('.twitter .time-ago').html(tweet.created_at);
        //
        //     })
        // };
        // updateTweet();

        // setInterval(function () {
        //     updateTweet();
        // }, 1000 * 60 * 3);
    });
}(jQuery));
